.history-by {
    margin: 0;
    padding: 0;
    display: inline-block;
}

@media (max-width: 1024px) {
    .history {
        font-size: 13px !important;
    }
    .history-bold {
        display: block;
    }
}