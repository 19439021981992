body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.dot {
  margin-top: 10px;
  width: 13px;
  height: 13px;
  background-color: #173660;
  border-radius: 100%;
  margin-left: auto;
  margin-right: 12vh;
}

/* hh - header hover */
.hh {
  background: #fafafa;
  border: 2px solid #f0f0f0;
}

.amplify-table__td {
  border: 2px solid #f0f0f0;
}

.hhb:hover{ 
  cursor: pointer;
  background:  #173660;
  color: rgb(218, 197, 122);
}

.switcher:hover {
  cursor: pointer;
}

@media (max-width: 1024px) {
  .amplify-table__th {
      font-size: 14px;
      padding: 0.5rem;
  }
}


