:root {
  --main: #173660;
  --dark-main: #132c4f;
  --light-main: #1f4882;
}

.amplify-tabs-item:active,
.amplify-tabs-item[data-state="active"] {
  color: var(--main);
  border-color: var(--main);
}

.amplify-button:hover {
  background-color: var(--light-main);
  border-color: var(--dark-main);
  color: white;
}

.amplify-button--primary,
.amplify-button--menu:hover,
.amplify-button--menu:focus,
.amplify-autocomplete__menu__option--active,
.amplify-autocomplete__menu__option--hover {
  background-color: var(--main);
}

.amplify-button--primary:hover {
  background-color: var(--light-main);
}

.amplify-button--small {
  color: var(--main);
}

.amplify-button--link:hover {
  background-color: var(--light-main);
  color: white;
}

.amplify-button--primary:focus,
.amplify-button:active {
  border-color: var(--light-main);
  color: white;
  background-color: var(--main);
}

.amplify-button--loading,
.amplify-button--disabled {
  color: white;
}

.amplify-loader line:last-of-type {
  stroke: var(--main);
}

div[role="tablist"] {
  display: none;
}