.user-table-img {
    border-radius: 50%;
}

table, th, td, caption {
    border: 1px solid rgb(239, 239, 239);
}

table, th,td {
    border-radius: 0px !important;
}